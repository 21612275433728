/**
 * Checks if a given title contains any word longer than a specified length, defaulting to 15 characters.
 *
 * @param {string} title - The title to check for long words.
 * @param {number} length - The minimum length of a word to qualify as 'long'.
 * @returns {boolean} True if a word longer than the specified length exists, false otherwise.
 *
 * @example
 * // Returns true because 'verylongwordindeedexample' is longer than 15 characters
 * containsWordOfLength('This is a verylongwordindeedexample for testing', 15);
 *
 * @example
 * // Returns true as there is a word longer than 10 characters
 * containsWordOfLength('Example of a somewhatlongword', 10);
 *
 * @example
 * // Returns false as all words are shorter than 15 characters
 * containsWordOfLength('This is a test with normal words', 15);
 */

function containsWordOfLength(title, length) {
  if (typeof title !== "string" || typeof length !== "number") {
    return false;
  }
  return title
    .trim()
    .split(/\s+/)
    .some((word) => word.length > length);
}

export default containsWordOfLength;
