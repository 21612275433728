import localeStringConverter from "~common/localeStringConverter";
import HomepageTemplate from "~templates/HomepageTemplate";
import { getHomepage } from "~templates/HomepageTemplate/getHomepage";
import withGlobalData from "~utils/nextjs/middleware/withGlobalData";
import withLayout from "~utils/withLayout";

const Home = (props) => <HomepageTemplate {...props} />;

export const getStaticProps = withGlobalData(async ({ locale }) => {
  const props = await getHomepage(localeStringConverter.toLocale(locale));

  if (!props?.Homepage) {
    return {
      props: {},
      notFound: true,
    };
  }
  return {
    props,
  };
});

export default withLayout(Home);
