import { arrayOf, number, oneOf, shape, string } from "prop-types";

import DuoColorText from "~components/DuoColorText";
import ImageComponent from "~components/ImageComponent";
import YoutubeEmbed from "~components/YoutubeEmbed";
import { ImageType } from "~types";
import classnames from "~utils/classnames";
import containsWordOfLength from "~utils/containsWordOfLength";

import classes from "./OverviewPageHero.module.scss";

const OverviewPageHero = ({
  type = "text",
  superTitle,
  title,
  titleEmphasis,
  intro,
  heroImage,
  youtubeId,
  youtubeTitle,
  imagePosition = "left",
  imageWidth = 628,
  imageHeight = 628,
}) => {
  switch (type) {
    case "image": {
      const imageComponent = heroImage?.[0] && (
        <div className={classes.image_column}>
          <div className={classes.imageBackground} />
          {youtubeId ? (
            <div className={classes.videoWrapper}>
              <YoutubeEmbed title={youtubeTitle} embedId={youtubeId} />
            </div>
          ) : (
            <div className={classes.imageWrapper}>
              <ImageComponent
                {...heroImage[0]}
                key={heroImage[0].src}
                priority
                width={imageWidth}
                height={imageHeight}
              />
            </div>
          )}
        </div>
      );
      return (
        <div className={classes.page_hero}>
          {imagePosition === "left" && imageComponent}
          <div className={classes.text_column}>
            <h1
              className={classnames(
                containsWordOfLength(title, 15) && classes.breakWord,
              )}
            >
              {superTitle && (
                <span className={classes.super_title}>{superTitle}</span>
              )}
              <DuoColorText
                className={classes.title}
                emphasisClassName={classes.title}
                text={title}
                emphasis={titleEmphasis}
              />
            </h1>
            <p className={classes.intro_text}>{intro}</p>
          </div>
          {imagePosition === "right" && imageComponent}
        </div>
      );
    }
    case "text":
    default:
      return (
        <div className={classes.page_hero}>
          <div className={classes.column}>
            <h1 className={classes.title}>
              <DuoColorText text={title} emphasis={titleEmphasis} />
            </h1>
            <p className={classes.intro_text}>{intro}</p>
          </div>
        </div>
      );
  }
};

OverviewPageHero.propTypes = {
  type: oneOf(["text", "image"]),
  superTitle: string,
  title: string.isRequired,
  titleEmphasis: string,
  intro: string,
  heroImage: arrayOf(shape(ImageType)),
  youtubeTitle: string,
  youtubeId: string,
  imagePosition: oneOf(["left", "right"]),
  imageWidth: number,
  imageHeight: number,
};

export default OverviewPageHero;
